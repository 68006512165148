import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
/** @jsx jsx */
import { Box, Grid, jsx } from "theme-ui"
import Layout from "../../components/layout"
import Img from "gatsby-image"
import SEO from "../../components/seo"
import EventGridFuture from "../../components/eventGridFuture"
import EventCard from "../../components/eventCard"
import HeaderBlockColor from "../../components/headerBlockColor"
import Header from "../../components/header"
import SanitizedHTMLBodyCopy from "../../components/sanitizedHTMLBodyCopy"

const WhatsOnScriptSessionsPage = () => {
  const data = useStaticQuery(graphql`
    query EventScriptSessionsQuery {
      allStrapiEvent(
        sort: { fields: Date }
        filter: { Type: { eq: "Script_Sessions" } }
      ) {
        edges {
          node {
            slug
            Biog
            Date
            dateFormatted: Date(formatString: "MMMM DD, YYYY")
            Img {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            Img_alt
            Open
            Title
            Type
            Venue
            strapiId
            Description
            Time
            blurb
          }
        }
      }
      strapiWhatson {
        coffee_with_creatives_blurb
        masterclasses_desc
        main_desc
        script_sessions_desc
      }
    }
  `)
  let today = new Date()
  const dd = String(today.getDate()).padStart(2, "0")
  const mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
  const yyyy = today.getFullYear()
  today = yyyy + "-" + mm + "-" + dd
  //futureevents
  const futureEvents = data.allStrapiEvent.edges.filter(item => {
    return item.node.Date >= today
  })
  const pastEvents = data.allStrapiEvent.edges.filter(item => {
    return item.node.Date < today
  })
  pastEvents.sort((a, b) => {
    if (a.node.Date > b.node.Date) {
      return -1
    }
    if (a.node.Date < b.node.Date) {
      return 1
    }
  })
  return (
    <Layout headerType="">
      <SEO title="Whats On" />
      <HeaderBlockColor />
      <Box
        sx={{
          mx: 4,
          "& > h1": { fontSize: 6, mb: 2 },
          "& .bodyCopy": { fontSize: 3, color: "muted", mb: 4 },
        }}
      >
        <Box
          sx={{
            mx: 3,
            background:
              "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
            transform: "rotate(-3deg) skew(-3deg) !important",
            py: 3,
            px: 3,
            position: "relative",
            mt: "-100px",
            mb: 3,
            display: "inline-block",
            "& h1": {
              color: "white",
              transform: "rotate(3deg) skew(3deg) !important",
            },
          }}
        >
          <h1>Script Sessions</h1>
        </Box>
        <SanitizedHTMLBodyCopy
          content={data.strapiWhatson.script_sessions_desc}
        />
        <h2>Future Sessions</h2>
        {!futureEvents.length && <p>There are currently none scheduled.</p>}
        <Grid gap={2} columns={[1, 2, 4]} sx={{ mb: 5 }}>
          {futureEvents.map((item, index) => {
            return <EventCard key={index} item={item} />
          })}
        </Grid>
        <h2>Past Sessions</h2>
        <Grid gap={2} columns={[1, 2, 4]} sx={{ mb: 4 }}>
          {pastEvents.map((item, index) => {
            // console.log(node)
            return (
              <EventCard key={`past-${index}`} item={item} className="past" />
            )
          })}
        </Grid>
      </Box>
    </Layout>
  )
}

export default WhatsOnScriptSessionsPage
